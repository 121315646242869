import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Histogram in D3v3 vs D3v4",
  "description": "D3v4 introduced just enough changes from D3v3 to trip you up.",
  "image": "../../../images/articles/screenshot-1591716513625.png",
  "date": "2016-10-25T08:00:00.000Z",
  "published": "2016-10-25T08:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`D3v4 has been out since June. That’s almost five months. That’s also just long enough to make my React+D3.js ES6 book crazy outdated and to fill my inbox with emails saying, `}<em parentName="p">{`"`}{`Yo, all your code is broken.”`}</em></p>
    <p>{`Yes, all my code is broken, and I`}{`'`}{`m `}<a parentName="p" {...{
        "href": "https://swizec.com/blog/work-on-reactd3js-es6-v2-begins-in-earnest/swizec/7010"
      }}>{`working on an update`}</a>{`. There were a ton of changes in D3v4, but how different is it, really?`}</p>
    <p>{`Not that different! The `}<a parentName="p" {...{
        "href": "https://swizec.com/blog/levenshtein-distance-in-haskell/swizec/4801"
      }}>{`Levenshtein distance`}</a>{` between a D3v3 histogram and a D3v4 histogram is only 82. It takes 82 1-character edits to go from a D3v3 histogram to a D3v4 histogram.`}</p>
    <p>{`That might sound like a bunch of edits, but the D3v4 example is a whopping 1026 characters. The D3v3 version is 1050.`}</p>
    <p>{`It’s 24 characters shorter and only 7.8% different. That doesn`}{`'`}{`t sound so bad, does it?`}</p>
    <p>{`Here`}{`'`}{`s a D3v3 histogram of a movie weekend dataset I found on a `}<a parentName="p" {...{
        "href": "https://ww2.amstat.org/publications/jse/jse_data_archive.htm"
      }}>{`list of datasets by the Journal of Statistical Education`}</a>{`. It uses ES6 and pure D3 without React.`}</p>
    <iframe {...{
      "src": "http://codepen.io/swizec/embed/preview/JRzWwj/",
      "style": {
        "width": "100%",
        "height": "300px"
      }
    }}></iframe>
    <p>{`We load some data with `}<inlineCode parentName="p">{`d3.tsv`}</inlineCode>{`, build a histogram with `}<inlineCode parentName="p">{`d3.layout.histogram`}</inlineCode>{`, set up a linear scale for each axis, and add a `}<inlineCode parentName="p">{`rect`}</inlineCode>{` to the SVG for each histogram entry.`}</p>
    <p>{`Now here’s the same dataset in D3v4:`}</p>
    <iframe {...{
      "src": "http://codepen.io/swizec/embed/preview/wzOddx/",
      "style": {
        "width": "100%",
        "height": "300px"
      }
    }}></iframe>
    <p>{`Same unstyled result, and the code looks samey too.`}</p>
    <p>{`Here are the differences:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`d3.layout.histogram`}</inlineCode>{` becomes `}<inlineCode parentName="li">{`d3.histogram`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.bins`}</inlineCode>{` becomes `}<inlineCode parentName="li">{`.thresholds`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`d3.scale.linear`}</inlineCode>{` becomes `}<inlineCode parentName="li">{`d3.scaleLinear`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`d.x+d.dx`}</inlineCode>{` becomes `}<inlineCode parentName="li">{`d.x1`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`d.y`}</inlineCode>{` becomes `}<inlineCode parentName="li">{`d.length`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`d.dx`}</inlineCode>{` becomes `}<inlineCode parentName="li">{`d.x1-d.x0`}</inlineCode></li>
    </ul>
    <p>{`Some of the API has been moved around and renamed to make more sense. Thresholds make more sense than bins, but scaleLinear vs. scale.linear… eh… sure. ¯`}{`_`}{`(ツ)`}<em parentName="p">{`/¯`}</em></p>
    <p>{`The biggest difference lies in histogram`}{`'`}{`s return value. Where before we would get an array of arrays with additional `}<inlineCode parentName="p">{`x`}</inlineCode>{`, `}<inlineCode parentName="p">{`y`}</inlineCode>{`, and `}<inlineCode parentName="p">{`dx`}</inlineCode>{` values, we now get just `}<inlineCode parentName="p">{`x0`}</inlineCode>{` and `}<inlineCode parentName="p">{`x1`}</inlineCode>{`.`}</p>
    <p>{`I like the `}<inlineCode parentName="p">{`x0, x1`}</inlineCode>{` approach because it tells us the lower and upper bound of each interval. This means less typing and thinking overhead in all cases except for one – when you do need the width. And I like the idea of relying on `}<inlineCode parentName="p">{`length`}</inlineCode>{` instead of having an extra `}<inlineCode parentName="p">{`y`}</inlineCode>{` property that`}{`'`}{`s the same as `}<inlineCode parentName="p">{`length`}</inlineCode>{`.`}</p>
    <p>{`I like all the changes, and I think they improve the library by leaps and bounds. But it`}{`'`}{`s these subtle differences that are the most annoying to catch.`}</p>
    <p>{`Curiously, D3v3 calculates a bin width of 1962.5, and D3v4 gets 2000. Same data, same amount of bins. ?`}</p>
    <p>{`Want more about the differences between D3v3 and D3v4? I`}{`'`}{`m thinking of making a cheatsheet. `}<a parentName="p" {...{
        "href": "https://twitter.com/intent/tweet?url=http%3A%2F%2Fswizec.com%2Fblog%2Fhistogram-d3v3-d3v4%2Fswizec%2F7145&text=Hey%20@swizec%21%20Where%27s%20that%20d3%20cheatsheet%20you%20promised%3F&"
      }}>{`Encourage me on Twitter`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      